import React, { useState, useEffect } from 'react'
import { Link, NavLink } from 'react-router-dom'
import store from '../../../redux/store'
import PropTypes from 'prop-types'
import { FaUserTie } from 'react-icons/fa'

const ManageAccountSideBar = (props) => {
  const state = store.getState()
  const allowDropship = JSON.parse(localStorage?.getItem('profile'))?.allow_dropship
  const isMasterCustomer = JSON.parse(localStorage?.getItem('profile'))?.isMasterCustomer
  const [name, setName] = useState('')
  const [allowedModules, setAllowedModules] = useState([])

  useEffect(() => {
    if (state.auth) {
      const user = state.auth.user
      const name = user.firstName + ' ' + user.lastName
      setName(name)
      setAllowedModules(state.auth.user.allowedModules)
    } else {
      setAllowedModules([])
    }
  }, [state.auth])
  const dashboardLogout = e => {
    e.preventDefault()
    localStorage.removeItem('profile')
    localStorage.removeItem('token')
    localStorage.removeItem('selectedLocation')
    sessionStorage.clear()
    // dispatch({ type: LOGIN_SUCCESS, payload: 0 })
    // history.push('/')
    setTimeout(() => {
      window.location = '/'
    }, 200)
  }

  return (
    <React.Fragment>
      <h4 className="account-nav__title" style={isMasterCustomer ? {background: 'antiquewhite'} : undefined}>
        <span className="rounded-circle bg-secondary mr-2 text-white" style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '60px',
          width: '56px'
        }}>
          { state?.auth?.user?.firstName?.charAt(0)}
          { state?.auth?.user?.lastName?.charAt(0)}
        </span>
        <div className="user-name-right">
          <span>Hello,</span>
          {name}
        </div>
        {isMasterCustomer && (
          <span 
            className="location-customer ml-2 d-flex align-items-center" 
            title="Master Account"
            style={{ padding: '4px 8px' }}
          >
            <FaUserTie style={{ fontSize: '14px' }} />
          </span>
        )}
      </h4>
      <div className="account-nav">
        {
          allowedModules !== undefined && allowedModules.length > 0 && <ul className="account-nav__list">
            <li className="account-nav__item">
                  <NavLink
                    exact
                    activeClassName="active"
                    className="nav-link"
                    to="/all-products"
                  >
                    <span>New Order</span>
                  </NavLink>
                </li>
            {
                allowedModules.findIndex(x => (x.permission === 'Order Management' || x.permission === 'Staff Order Management') && x.isAccessible === 1) !== -1
                  ? <li className="account-nav__item ">
                  <NavLink
                    exact
                    activeClassName="active"
                    className="nav-link"
                    to="/order-csv"
                  >
                    <span>Bulk Ordering</span>
                  </NavLink>
                </li>
                  : ''
              }
              {
                isMasterCustomer ?
                <li className="account-nav__item ">
                <NavLink
                  exact
                  activeClassName="active"
                  className="nav-link"
                  to="/child-customer-management"
                >
                  <span>Child Customer Management</span>
                </NavLink>
              </li>
              : ''
              }
          {
                allowedModules.findIndex(x => (x.permission === 'Order Management' || x.permission === 'Staff Order Management') && x.isAccessible === 1) !== -1
                  ? <li className="account-nav__item">
                  <NavLink
                    exact
                    activeClassName="active"
                    className="nav-link"
                    to="/dashboard"
                  >
                    <span>Order History</span>
                  </NavLink>
                </li>
                  : ''
              }
              {
                props.openAccount && allowedModules.findIndex(x => (x.permission === 'Order Management' || x.permission === 'Open Account Management') && x.isAccessible === 1) !== -1
                  ? <li className="account-nav__item">
                  <NavLink
                    exact
                    activeClassName="active"
                    className="nav-link"
                    to="/invoices"
                  >
                    <span>Invoices / Make Payment</span>
                  </NavLink>
                </li>
                  : ''
              }
              <li className="account-nav__item">
                  <NavLink
                    exact
                    activeClassName="active"
                    className="nav-link"
                    to="/preferred-products"
                  >
                    <span>Preferred Products</span>
                  </NavLink>
                </li>
              {
                allowedModules.findIndex(x => x.permission === 'Account Details' && x.isAccessible === 1) !== -1
                  ? <li className="account-nav__item ">
                  <NavLink
                    exact
                    activeClassName="active"
                    className="nav-link"
                    to="/account-details"
                  >
                    <span>Account Details</span>
                  </NavLink>
                </li>
                  : ''
              }
              {
                allowedModules.findIndex(x => x.permission === 'Location Management' && x.isAccessible === 1) !== -1
                  ? <li className="account-nav__item ">
                  <NavLink
                    exact
                    activeClassName="active"
                    className="nav-link"
                    to="/location-management"
                  >
                    <span>Location Management</span>
                  </NavLink>
                </li>
                  : ''
              }
              {
                (allowedModules.findIndex(x => x.permission === 'Order Management' && x.isAccessible === 1) !== -1 && allowedModules.findIndex(x => x.permission === 'Location Management' && x.isAccessible === 1) !== -1 &&
                allowedModules.findIndex(x => x.permission === 'Account Details' && x.isAccessible === 1) !== -1 && allowedModules.findIndex(x => x.permission === 'Payment Methods' && x.isAccessible === 1) !== -1 &&
                allowedModules.findIndex(x => x.permission === 'Reports' && x.isAccessible === 1) !== -1)
                  ? <li className="account-nav__item">
                  <NavLink
                    exact
                    activeClassName="active"
                    className="nav-link"
                    to="/staff-management"
                  >
                    <span>Staff Management</span>
                  </NavLink>
                </li>
                  : ''
              }
              {
                allowDropship ?
                <li className="account-nav__item ">
                <NavLink
                  exact
                  activeClassName="active"
                  className="nav-link"
                  to="/staff-account-pricing"
                >
                  <span>Staff Account Pricing</span>
                </NavLink>
              </li>
              : ''
              }
              {
                allowedModules.findIndex(x => x.permission === 'Payment Methods' && x.isAccessible === 1) !== -1 ?
                <li className="account-nav__item ">
                <NavLink
                  exact
                  activeClassName="active"
                  className="nav-link"
                  to="/manage-payments"
                >
                  <span>Saved Payment Methods</span>
                </NavLink>
              </li>
              : ''
              }
          
            {
                allowedModules.findIndex(x => x.permission === 'Reports' && x.isAccessible === 1) !== -1
                  ? <li className="account-nav__item ">
                  <NavLink
                    exact
                    activeClassName="active"
                    className="nav-link"
                    to="/reports"
                  >
                    <span>Reports</span>
                  </NavLink>
                </li>
                  : ''
              }
            {/* <li className="account-nav__item ">
              <NavLink
                exact
                activeClassName="active"
                className="nav-link"
                to="/#"
              >
                <span>Returns</span>
              </NavLink>
            </li> */}
              {
                allowedModules.findIndex(x => x.permission === 'Order Management' && x.isAccessible === 1) !== -1
                  ? <li className="account-nav__item">
                  <NavLink
                    exact
                    activeClassName="active"
                    className="nav-link"
                    to="/disputes"
                  >
                    <span>Dispute Management</span>
                  </NavLink>
                </li>
                  : ''
              }
            <li className="account-nav__item ">
              <NavLink
                exact
                activeClassName="active"
                className="nav-link"
                to="/change-password"
              >
                <span>Change Password</span>
              </NavLink>
            </li>
            <li className="account-nav__item logout-nav">
              <span className="logout-btn" onClick={dashboardLogout}>
              <Link to="" className="logout-btn">
                Logout
              </Link>
              </span>
            </li>
          </ul>
        }
      </div>
    </React.Fragment>
  )
}

export default React.memo(ManageAccountSideBar)

ManageAccountSideBar.propTypes = {
  openAccount: PropTypes.bool,
  children: PropTypes.any
}
